import { doc, getFirestore, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { AppFirebase } from "../../service/firebase.js";
import Usuario from "../usuario/index.js";

import * as C from './styles.js'
import { APIKey } from "../../config/key.js";

export default function AddMovieApp() {

    const inputUrl = useRef();
    const buttonSend = useRef();

    // add movie
    const [ movie, setMovie ] = useState({});
    const [ player, setPlayer ] = useState('moov');
    const [ name, setName ] = useState();
    const [ overview, setOverview ] = useState();
    const [ logo, setLogo ] = useState();
    const [ season, setSeason ] = useState(1);
    const [ episode, setEpisode ] = useState(1);
    const [ totalEpisodes, setTotalEpisodes ] = useState();
    const [ url, setUrl ] = useState('https://filemoon.sx/e/');
    const [ url_path, set_url_path ] = useState('https://lightspeedst.net/s4/mp4/one-piece/sd/');
    const [ movieType, setMovieType ] = useState('movie');
    const [ id, setId ] = useState();
    const [ idImdb, setIdImdb ] = useState();
    const [ ageRating, setAgeRating ] = useState();
    const [ ageRatingDescription,setAgeRatingDescription ] = useState();
    const [ backdropPath,setBackdropPath ] = useState();
    const [ isAnime, setIsAnime ] = useState(false);
    const [ nextEpisode, setNextEpisode ] = useState(null);
    const [ startOpening, setStartOpening ] = useState(null);
    const [ endOpening, setEndOpening ] = useState(null);
    const [ movieRecommendedId, setMovieRecommendedId ] = useState(null);

    const [canWatchOn, setCanWatchOn] = useState({
        web: false,
        app: false,
        tv: false
    });

    useEffect(()=> {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, [ ]);

    const handleCheckboxChange = (e) => {
        setCanWatchOn({
            ...canWatchOn,
            [e.target.name]: e.target.checked
        });
    };
    
    async function addTvSerie(e) {

        e.preventDefault();

        const selectedOptions = Object.keys(canWatchOn).filter(option => canWatchOn[option]);

        const database = getFirestore(AppFirebase);
            
        await setDoc(doc(database, `watch/${idImdb}`), {
            name: name,
            overview: overview,
            id_tmdb: id,
            id_imdb: idImdb,
            can_watch_on: selectedOptions,
            media_type: 'tv',
            backdrop_path: backdropPath,
            logo_path: logo,
            age_rating: ageRating,
            age_rating_description: ageRatingDescription,
            is_anime: isAnime,
            more_details: movie,
            date: serverTimestamp(),
        }).then(()=>{
            alert("Informações da série adicionadas");
            inputUrl.current.focus();
        }).catch((erro)=>{
            alert(erro)
        });
    }

    async function submit(e) {
        e.preventDefault();

        const selectedOptions = Object.keys(canWatchOn).filter(option => canWatchOn[option]);

        const database = getFirestore(AppFirebase);

        if (movieType === 'tv') {
            fetch(`https://api.themoviedb.org/3/tv/${id}/season/${season}/episode/${episode}?api_key=${APIKey}&language=pt-BR`)
                .then(response => response.json())
                .then(data => {
                    setDoc(doc(database, `watch/${idImdb}/${season}/${episode}`), {
                        media_type: 'tv',
                        src: url,
                        can_watch_on: selectedOptions,
                        player: player,
                        age_rating: ageRating,
                        age_rating_description: ageRatingDescription,
                        show_next: nextEpisode,
                        end_opening: endOpening,
                        start_opening: startOpening,
                        backdrop_path: backdropPath,
                        logo_path: logo,
                        episode_details: data,
                        season_episode: season,
                        date: serverTimestamp(),
                    }).then(() => {
                        setEpisode(Number(episode) + 1);
                        alert("Episódio adicionado");
                        inputUrl.current.focus();
                    }).catch((erro) => {
                        alert(erro);
                    });
                });
        } else if (movieType === 'movie') {
            fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${APIKey}&language=pt-BR`)
                .then(response => response.json())
                .then(data => {
                    setDoc(doc(database, `watch/${idImdb}`), {
                        name: name,
                        overview: data.overview,
                        can_watch_on: selectedOptions,
                        player: player,
                        src: url,
                        id_tmdb: id,
                        id_imdb: idImdb,
                        media_type: 'movie',
                        age_rating: ageRating,
                        age_rating_description: ageRatingDescription,
                        movie_recommended_id: movieRecommendedId,
                        show_next: nextEpisode,
                        backdrop_path: backdropPath,
                        logo_path: logo,
                        more_details: data,
                        date: serverTimestamp(),
                    }).then(() => {
                        alert("Filme adicionado");
                    }).catch((erro) => {
                        alert(erro);
                    });
                });
        }
    }

    function getMovie(e) {
        e.preventDefault();
        fetch(`https://api.themoviedb.org/3/${movieType}/${id}?api_key=${APIKey}&vote_count.gte=10&language=pt-BR`)
        .then(Response => Response.json())
        .then(data => {
            setMovie(data)
            setName(data.title ? data.title : data.name);
            setOverview(data.overview);
        });
        
        fetch(`https://api.themoviedb.org/3/${movieType}/${id}/external_ids?api_key=${APIKey}&language=pt-BR`)
        .then(Response => Response.json())
        .then(data => {
            setIdImdb(data.imdb_id);
        });
        

        if (movieType == 'tv') {
            fetch(`https://api.themoviedb.org/3/tv/${id}/content_ratings?api_key=${APIKey}`)
            .then(Response => Response.json())
            .then(data => {
                data.results.map((rating)=>{
                    if(rating.iso_3166_1 == "BR") {
                        setAgeRating(rating.rating)
                    }
                })
            });
        } else {
            fetch(`https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=${APIKey}`)
            .then(Response => Response.json())
            .then(data => {
    
                data.results.map((certification)=>{
                    if(certification.iso_3166_1 == "BR" ) {
                        setAgeRating(certification.release_dates[0].certification);
                    }
                })
            });
        }
    }

    return (
        <>
            <C.Container>
                <C.Content>
                        <C.Form method="" action="" onSubmit={submit}>
                            <C.Header>
                                <C.Button active={movieType == 'movie'} onClick={()=>setMovieType('movie')}>Filme</C.Button>
                                <C.Button active={movieType == 'tv'} onClick={()=>setMovieType('tv')}>Série</C.Button>
                            </C.Header>
                            <C.Header>
                                <C.Button active={player == 'moov'} onClick={()=>setPlayer('moov')}>Moov player</C.Button>
                                <C.Button active={player == 'iframe'} onClick={()=>setPlayer('iframe')}>Iframe</C.Button>
                            </C.Header>
                            <C.Header>
                                <C.Button active={!isAnime} onClick={()=>setIsAnime(false)}>Filme / série</C.Button>
                                <C.Button active={isAnime} onClick={()=>setIsAnime(true)}>Anime</C.Button>
                            </C.Header>
                            <div>
                                <label>
                                    <input type="checkbox" name="web" checked={canWatchOn.web} onChange={handleCheckboxChange} />
                                    Web
                                </label>
                                <label>
                                    <input type="checkbox" name="app" checked={canWatchOn.app} onChange={handleCheckboxChange} />
                                    App
                                </label>
                                <label>
                                    <input type="checkbox" name="tv" checked={canWatchOn.tv} onChange={handleCheckboxChange} />
                                    TV
                                </label>
                            </div>
                            {isAnime &&
                                <>
                                    <C.Input required type='text' placeholder="Total de episódios" value={totalEpisodes} onChange={(e)=>setTotalEpisodes(e.target.value)}/>
                                    <C.Input required type='text' placeholder="Url path" value={url_path} onChange={(e)=>set_url_path(e.target.value)}/>
                                </>
                            }
                            <div>
                                <C.Input required type='text' placeholder="id" value={id} onChange={(e)=>setId(e.target.value)}/>
                                <C.Button onClick={getMovie}><i className="fas fa-search"></i></C.Button>
                            </div>
                            <C.Input required type='text' placeholder="Nome" value={name} onChange={(e)=>setName(e.target.value)}/>
                            <C.Input required type='text' placeholder="idImdb" value={idImdb} onChange={(e)=>setId(e.target.value)}/>
                            <C.Input required type='text' placeholder="Classificação indicativa" value={ageRating} onChange={(e)=>setAgeRating(e.target.value)}/>
                            <C.Input required type='text' placeholder="Descrição da classificação indicativa" value={ageRatingDescription} onChange={(e)=>setAgeRatingDescription(e.target.value)}/>
                            <C.Input required type='text' placeholder="Imagem de fundo com logo" value={backdropPath} onChange={(e)=>setBackdropPath(e.target.value)}/>
                            <C.Input required type='text' placeholder="Logo" value={logo} onChange={(e)=>setLogo(e.target.value)}/>
                            <C.Input required type='text' placeholder="Inicio da abertura (em segundos)" value={startOpening} onChange={(e)=>setStartOpening(e.target.value)}/>
                            <C.Input required type='text' placeholder="Fim da abertura (em segundos)" value={endOpening} onChange={(e)=>setEndOpening(e.target.value)}/>
                            <C.Input required type='text' placeholder="Exibir botão próximo episódio (em segundos)" value={nextEpisode} onChange={(e)=>setNextEpisode(e.target.value)}/>
                            {movieType == 'movie' &&
                                <C.Input required type='text' placeholder="Filme recomendado (id do filme)" value={movieRecommendedId} onChange={(e)=>setMovieRecommendedId(e.target.value)}/>
                            }
                            {movieType == 'tv' &&
                                <>
                                    <C.Input required type='text' placeholder="Temporada" value={season} onChange={(e)=>setSeason(e.target.value)}/>
                                    <C.Input required type='text' placeholder="Episodio" value={episode} onChange={(e)=>setEpisode(e.target.value)}/>
                                </>
                            }
                            <C.Input required ref={inputUrl} type='text' placeholder="Src" value={url} onChange={(e)=>setUrl(e.target.value)}/>
                            {movieType == 'tv' &&
                                <C.Send onClick={addTvSerie}>Adicionar info série</C.Send>
                            }
                            <C.Send ref={buttonSend}>Enviar</C.Send>
                        </C.Form>
                </C.Content>
            </C.Container>
        </>
    )
}