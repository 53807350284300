import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import { AppFirebase } from "../../../../service/firebase";

export default function GetRequests({ setRequests }) {
    const database = getFirestore(AppFirebase);

    useEffect(() => {
        const requestsRef = query(
            collection(database, `request_movies`),
            orderBy('date', 'desc')
        );
        const unsubscribe = onSnapshot(requestsRef, (snapshot) => {
            const allDocuments = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const finishedDocuments = allDocuments.filter(doc => doc.status === 'finished');
            const laterDocuments = allDocuments.filter(doc => doc.status === 'later');
            const noStatusDocuments = allDocuments.filter(doc => !doc.status);
            
            const sortedDocuments = allDocuments.sort((a, b) => {
                if (a.status === 'finished' && b.status !== 'finished') {
                    return 1;
                } else if (a.status !== 'finished' && b.status === 'finished') {
                    return -1;
                } else {
                    return 0;
                }
            });
            
            console.log("Pesquisou requests")
            setRequests({
                requests: { items: sortedDocuments, length: sortedDocuments.length },
                requests_finished: { items: finishedDocuments, length: finishedDocuments.length },
                requests_later: { items: laterDocuments, length: laterDocuments.length },
                requests_active: { items: noStatusDocuments, length: noStatusDocuments.length },
            });
        }, (error) => {
            console.error('Ocorreu um erro ao solicitar requests:', error);
        });


        return () => unsubscribe();
    }, [database, setRequests]);
}